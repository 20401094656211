<ng-container>
  <div class="form-req">
    <div *ngIf="title" class="form-req__header">
      {{title | translate}}
    </div>
  <form class="form-req__wrapper" [formGroup]="reqForm" (ngSubmit)="onSubmit()">
    <div  *ngIf="isErrorMessageVisible" class="form-req__error">Oops! Please, fill all fields</div>
    <div class="form-req__contacts">
      <div class="form-req__input-wrapper">
        <div class="form-req__input-title">{{"feedback-form.name" | translate}}</div>
        <input class="form-req__input" type="text" formControlName="name">
      </div>
      <div class="form-req__input-wrapper">
        <div class="form-req__input-title">{{"feedback-form.email" | translate}}</div>
        <input class="form-req__input" type="text" formControlName="email">
      </div>
      <div class="form-req__input-wrapper">
        <div class="form-req__input-title">{{"feedback-form.number" | translate}}</div>
        <input class="form-req__input" type="text" formControlName="phone">
      </div>
    </div>
    <div class="form-req__textarea-wrapper textarea-wrapper1">
      <div class="form-req__textarea-title">{{"feedback-form.message-subject" | translate}}</div>
      <textarea rows="3" class="form-req__textarea" formControlName="subject"></textarea>
    </div>
    <div class="form-req__textarea-wrapper textarea-wrapper2">
      <div class="form-req__textarea-title">{{"feedback-form.message-body" | translate}}</div>
      <textarea rows="6" class="form-req__textarea" formControlName="body"></textarea>
    </div>
    <div  *ngIf="isProblemsMessageVisible" class="form-req__error">Oops! Something went wrong, please, try again in some minutes</div>
    <div  *ngIf="isSuccessMessageVisible" class="form-req__success">Email was successfully recieved!</div>

    <button class="form-req__submit-button" type="submit">{{"feedback-form.submit" | translate}}</button>
  </form>
  </div>
</ng-container>


