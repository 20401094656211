import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { SwiperComponent } from "swiper/angular";
// import Swiper core and required modules
import SwiperCore, { Virtual, SwiperOptions} from 'swiper';

// install Swiper modules
SwiperCore.use([Virtual]);

@Component({
  selector: 'app-product-swiper',
  templateUrl: './product-swiper.component.html'
})
export class AppProductSwiperComponent  implements OnInit{

  @Input() images;

  imagesArr = [];

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @ViewChild('swiper1', { static: false }) swiper1?: SwiperComponent;

  config: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 50,
    effect: 'coverflow',
  };

  config1: SwiperOptions = {
    slidesPerView: 4,
    spaceBetween: 10,
    effect: 'coverflow',
  };


  ngOnInit() {
    if (this.images) {
      this.imagesArr = this.images.split(";");
    }
    else {
      this.imagesArr.push('./assets/default.jpg');
    }
  }

  slideNext(){
    this.swiper.swiperRef.slideNext(100);
    this.swiper1.swiperRef.slideNext(100);
  }
  slidePrev(){
    this.swiper.swiperRef.slidePrev(100);
    this.swiper1.swiperRef.slidePrev(100);

  }

  changeActiveSlide(id:any) {
    this.swiper1.swiperRef.slideTo(id, 100);
    this.swiper.swiperRef.slideTo(id, 100);
  }

}
