<div class="contacts">
  <div class="contacts__header">
    {{"contacts.header" | translate}}
  </div>
  <div class="contacts__info">
    <div class="contacts__info-section">
      <div class="contacts__info-title">
        {{"contacts.address" | translate}}
      </div>
      <p class="contacts__info-text">
        {{"contacts.address-value" | translate}}
      </p>
    </div>
    <div class="contacts__info-section">
      <div class="contacts__info-title">
        {{"contacts.address2" | translate}}
      </div>
      <p class="contacts__info-text">
        {{"contacts.address2-value" | translate}}
      </p>
    </div>
    <div class="contacts__info-section">
      <div class="contacts__info-title">
        {{"contacts.email" | translate}}
      </div>
      <p class="contacts__info-text">
        {{"contacts.email-value" | translate}}
      </p>
    </div>
    <div class="contacts__info-section">
      <div class="contacts__info-title">
        {{"contacts.phone" | translate}}
      </div>
      <p class="contacts__info-text">
        {{"contacts.phone-value" | translate}}
      </p>
    </div>
    <div class="contacts__info-section">
      <div class="contacts__info-title">
        {{"contacts.work-time" | translate}}
      </div>
      <div class="contacts__info-text">
        <img src="./assets/svg-icons/clock.svg" alt="clock"><p>{{"contacts.Mo" | translate}} — 8:00 - 17:00</p>
      </div>
      <div class="contacts__info-text">
        <img src="./assets/svg-icons/clock.svg" alt="clock"><p>{{"contacts.Tu" | translate}} — 8:00 - 17:00</p>
      </div>
      <div class="contacts__info-text">
        <img src="./assets/svg-icons/clock.svg" alt="clock"><p>{{"contacts.We" | translate}} — 8:00 - 17:00</p>
      </div>
      <div class="contacts__info-text">
        <img src="./assets/svg-icons/clock.svg" alt="clock"><p>{{"contacts.Th" | translate}} — 8:00 - 17:00</p>
      </div>
      <div class="contacts__info-text">
        <img src="./assets/svg-icons/clock.svg" alt="clock"><p>{{"contacts.Fr" | translate}} — 8:00 - 17:00</p>
      </div>
    </div>
  </div>
</div>
