<div class="create-product">
    <div class="create-product__wrapper">
        <h1 class="create-product__header">Update product</h1>
        <p class="create-product__label">Status:</p>
        <ng-multiselect-dropdown
        [placeholder]="'status...'"
        [settings]="statusDropdownSettings"
        [data]="statusDropdownList"
        [(ngModel)]="statusSelectedItem"
        >
        </ng-multiselect-dropdown>

        <p class="create-product__label">Tags:</p>
        <ng-multiselect-dropdown
        [placeholder]="'tags...'"
        [settings]="tagsDropdownSettings"
        [data]="tagsDropdownList"
        [(ngModel)]="tagsSelectedItems"
         >
        </ng-multiselect-dropdown>

        <p class="create-product__label">Engine*:</p>
        <ng-multiselect-dropdown
        [placeholder]="'engine...'"
        [settings]="engineDropdownSettings"
        [data]="engineDropdownList"
        [(ngModel)]="engineSelectedItem"
        >
        </ng-multiselect-dropdown>
        <div *ngIf="!engineSelectedItem && isSubmitted"  class="create-product__form-invalid err-message">Please, select engine value</div>

        <p class="create-product__label">Mast*:</p>
        <ng-multiselect-dropdown
        [placeholder]="'mast...'"
        [settings]="mastDropdownSettings"
        [data]="mastDropdownList"
        [(ngModel)]="mastSelectedItem"
        >
        </ng-multiselect-dropdown>
        <div *ngIf="!mastSelectedItem && isSubmitted"  class="create-product__form-invalid err-message">Please, select mast value</div>


        <p class="create-product__label">Images:</p>
        <div class="create-product__images">
            <ik-upload 
                fileName="test.jpg" 
                (onError)="handleUploadError($event)"
                (onSuccess)="handleUploadSuccess($event)" 
                >
            </ik-upload>

            <div *ngIf="imagesArr.length" class="create-product__images-arr">
                <div class="create-product__images-image" *ngFor="let image of imagesArr; let i = index">
                    <img [src]="image" alt="product">   
                    <div class="create-product__icon cross" (click)="onDelete(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>                    
                    </div>                    
                </div>
            </div>
        </div>

        <form *ngIf="productForm" class="create-product__form edit" [formGroup]="productForm" (ngSubmit)="onSubmit()">
            <label class="create-product__label" for="name">Product name*:</label>
            <input formControlName="name" class="create-product__input" type="text" id="name" placeholder="name...">

            <label class="create-product__label" for="price">Price* (in zl):</label>
            <input formControlName="price" class="create-product__input" type="number" id="price" placeholder="1500...">
        
            <label class="create-product__label" for="weight">Load capacity* (t):</label>
            <input formControlName="weight" class="create-product__input" type="number" id="weight" placeholder="1.5 t...">

            <label class="create-product__label" for="maxHeight">Lifting height* (m):</label>
            <input formControlName="maxHeight" class="create-product__input" type="number" id="maxHeight" placeholder="1.3 m...">

            <label class="create-product__label" for="height">Height* (m):</label>
            <input formControlName="height" class="create-product__input" type="number" id="height" placeholder="2 m...">

            <label class="create-product__label" for="transmission">Transmission*:</label>
            <input formControlName="transmission" class="create-product__input" type="text" id="transmission" placeholder="automatic transmission / manual...">

            <label class="create-product__label" for="optionalEquipment">Optional equipment:</label>
            <input formControlName="optionalEquipment" class="create-product__input" type="text" id="optionalEquipment" placeholder="optional equipment...">

            <label class="create-product__label" for="extraInformation">Extra information:</label>
            <input formControlName="extraInformation" class="create-product__input" type="text" id="extraInformation" placeholder="extra information...">
        
            <div  *ngIf="isErrorMessageVisible" class="create-product__form-invalid">Please, fill all required field (marked with *)</div>

            <button class="create-product__button" type="submit">Submit</button>
            
        </form>
    </div>
</div>