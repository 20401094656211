import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/lib/services/data/data.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-edit-product-page',
  templateUrl: './edit-product.component.html'
})
export class AppEditProductPageComponent implements OnInit {

  constructor(private dataService: DataService, private route: ActivatedRoute, private router: Router) {}

  isSubmitted = false;


  statusDropdownList = [
    {
      id: 1, statusName:'new'
    },
    {
      id: 2, statusName:'sale'
    }
  ];
  selectedStatus;
  statusSelectedItem;
  statusDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'statusName',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  tagsDropdownList = [];
  tagsSelectedItems = [];
  tagsDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'tagName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  engineDropdownList = [
    {
      id: 1, engineName:'gasoline'
    },
    {
      id: 2, engineName:'lpg'
    },
    {
      id: 3, engineName:'diesel'
    },
    {
      id: 4, engineName:'electro'
    }
  ];
  engineSelectedItem;
  engineDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'engineName',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  mastDropdownList = [
    {
      id: 1, mastName:'duplex'
    },
    {
      id: 2, mastName:'duplex with free lift'
    },
    {
      id: 3, mastName:'triplex with free lift'
    }
  ];
  mastSelectedItem;
  mastDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'mastName',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };


  productForm: FormGroup;

  isErrorMessageVisible:boolean = false;
  productId = this.route.snapshot.params['id'];

  imagesArr = [];
  imagesArr$ = new Subject<any>();

  initialProductTags = [];


  ngOnInit(): void {

    this.dataService.getTags().subscribe((data) => {
      this.tagsDropdownList = data.data.tags;
    });

    this.imagesArr$.subscribe( (res) => {
      this.imagesArr = res;
      }
    );

    this.dataService.getProductById(this.productId).subscribe((data) => {
      const product = data.data.entities_by_pk;
      if (product?.status) {
        this.statusSelectedItem = this.statusDropdownList.filter(list => list.statusName === product.status);
      }
      if(product?.engine) {
        let enginesArr=product.engine.split(',');
        this.engineSelectedItem = this.engineDropdownList.filter(list => {
          return enginesArr.includes(list.engineName);
        });
      }
      if (product?.mastType) {
        this.mastSelectedItem = this.mastDropdownList.filter(list => list.mastName === product.mastType);
      }
      if (product?.entities_tags) {
        this.tagsSelectedItems = product.entities_tags.map(({ tag }) => tag);
        this.initialProductTags = product.entities_tags;

      }

      if(product?.images) {
        const imagesArr = product.images.split(";");
        this.imagesArr = imagesArr;
        this.imagesArr$.next(imagesArr);
      }
      this.productForm = new FormGroup({
        name: new FormControl(product.name, Validators.required),
        price: new FormControl(product.cost, Validators.required),
        weight: new FormControl(product.maxWeight, Validators.required),
        maxHeight: new FormControl(product.maxHeight, Validators.required),
        height: new FormControl(product.height, Validators.required),
        engine: new FormControl(product.engine, Validators.required),
        year: new FormControl(product.year, Validators.required),
        transmission: new FormControl(product.transmission, Validators.required),
        optionalEquipment: new FormControl(product.extraFeatures),
        extraInformation: new FormControl(product.description),
      });
    });
  }

  onStatusSelect(item: any) {
    this.selectedStatus = item;
  }

  handleUploadError(an:any) {
  }

  handleUploadSuccess(image:any) {
    this.imagesArr.push(image.url);
    this.imagesArr$.next(this.imagesArr.slice());
  }

  onSubmit() {
    this.isErrorMessageVisible = false;
    const controls = this.productForm.controls;
    this.isSubmitted = true;
    if(this.productForm.valid) {
      this.dataService.updateProduct(controls, this.engineSelectedItem, this.mastSelectedItem, this.statusSelectedItem, this.imagesArr, this.productId).subscribe(() =>{
        if(this.tagsSelectedItems.length) {
          const removedTagsArr = [];
          const addedTagsArr = [];


          const removedTags = this.initialProductTags.filter(({ tag: prevTag }) => !this.tagsSelectedItems.find((curTag) => prevTag.id === curTag.id));
          const addedTags = this.tagsSelectedItems.filter((curTag) => !this.initialProductTags.find(({ tag: prevTag }) => curTag.id === prevTag.id));

          if (removedTags.length) {
            for(let i=0; i<removedTags.length; i++) {
              removedTagsArr.push( removedTags[i].tag.id);
            }
            this.dataService.deleteTagsToProduct(this.productId,removedTagsArr).subscribe();
          }

          if (addedTags.length) {
            for(let i=0; i<addedTags.length; i++) {
              addedTagsArr.push({entityId: this.productId, tagId: addedTags[i].id})
            }
            this.dataService.assignTagsToProduct(addedTagsArr).subscribe();
          }

          this.tagsSelectedItems = [];
          this.initialProductTags = [];
        } 
        this.productForm.reset();
        this.productForm.markAsUntouched();
        this.isSubmitted = false;
        this.clearAllDropdowns();
        this.router.navigateByUrl('/products');
      }
      );


    } else {
      this.isErrorMessageVisible = true;
    }
 

  }

  clearAllDropdowns() {
    this.engineSelectedItem = [];
    this.mastSelectedItem = [];
    this.statusSelectedItem = [];
    this.tagsSelectedItems = [];
    this.imagesArr = [];
    this.imagesArr$.next([]);
  }

  onDelete(index: number) {
    this.imagesArr.splice(index, 1);
    this.imagesArr$.next(this.imagesArr.slice());
  }
}
