<header class="header" clickOutside (clickOutside)="clickedOutside()">
  <div class="burger-menu">
    <input  [(ngModel)]="isChecked" type="checkbox"  class="openSidebarMenu" id="openSidebarMenu" (change)="changeMenuOpen()">
    <label for="openSidebarMenu" class="sidebarIconToggle">
        <div class="spinner diagonal part-1"></div>
        <div class="spinner horizontal"></div>
        <div class="spinner diagonal part-2"></div>
    </label>
  </div>
  <div class="header__logo">
    <a [routerLink]="['/products']" class="header__logo-green-logo">
      <img src="./assets/logo.png"/>
    </a>
    <div class="header__logo-text">
      <a [routerLink]="['/products']">
        <img src="./assets/logoText.png"/>
      </a>
    </div>
  </div>
  <ul class="header__nav">
    <li *ngFor="let link of headerLinks">
      <a [routerLink]="link.url" routerLinkActive="header__nav-link_active" class="header__nav-link">
        {{link.name | translate}}
      </a>
        <div *ngIf="link?.subLinks" class="nav-panel__wrapper">
          <ul class="nav-panel">
            <li *ngFor="let sublink of link.subLinks">
              <a [routerLink]="sublink.url" class="nav-panel__link">
                <div class="nav-panel__hor-line"></div>
                <div class="nav-panel__link-item">{{sublink.name | translate}}</div>
              </a>
            </li>
          </ul>
        </div>
    </li>
    <div class="header__extra">
      <div class="header__lang">
        <img src="../../../assets/svg-icons/globe.svg" alt="globe">
        <div class="nav-panel__wrapper">
          <ul class="nav-panel">
            <li *ngFor="let lang of languages" class="nav-panel__link" >
              <div class="nav-panel__hor-line"></div>
              <div class="nav-panel__link-item" (click)="onChangeLanguage(lang)">
                {{lang}}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <li *ngIf="isAdminFlag">
        <div *ngIf="isLoggedIn; else login">
          <img src="../../../assets/svg-icons/user.svg" alt="user" class="header__user">
          <div class="nav-panel__wrapper">
            <ul class="nav-panel">
              <li>
                <a routerLink="/create-product" class="nav-panel__link">
                  <div class="nav-panel__hor-line"></div>
                  <div class="nav-panel__link-item">{{"navigation.create-product" | translate}}</div>
                </a>
              </li>
              <li>
                <a routerLink="/create-tag" class="nav-panel__link">
                  <div class="nav-panel__hor-line"></div>
                  <div class="nav-panel__link-item">{{"navigation.create-tag" | translate}}</div>
                </a>
              </li>
              <li>
                <div routerLink="/create-tag" class="nav-panel__link" (click)="logout()">
                  <div class="nav-panel__hor-line"></div>
                  <div class="nav-panel__link-item">{{"navigation.logout" | translate}}</div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <ng-template #login>
          <a *ngIf="isAdminFlag" routerLink="/login" routerLinkActive="header-link_active" class="header__nav-link">Log in</a>
        </ng-template>
       
      </li>
    </div>

  </ul>
  <div *ngIf="isMenuOpen" class="header-mobile__wrapper">
    <ul class="header-mobile__nav" (click)="clickedOutside()">
      <li *ngFor="let link of headerLinks">
        <a [routerLink]="link.url" routerLinkActive="header-link_active" class="header-mobile__nav-link">{{link.name | translate}}</a>
       <ul class="nav-panel">
         <li class="nav-panel__link" *ngFor="let sublink of link.subLinks">
           <a [routerLink]="sublink.url" routerLinkActive="nav-panel__link_active" [routerLinkActiveOptions]="{exact:true}">
            <div class="nav-panel__hor-line"></div>
            <span>{{sublink.name | translate}}</span>
           </a>
         </li>
       </ul>
      </li>
    </ul>
    <div class="header-mobile__extra-links">
      <div class="extra-links__lang">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"/></svg>
        <ul class="nav-panel">
          <li class="nav-panel__link" *ngFor="let lang of languages">
             <div class="nav-panel__hor-line"></div>
             <div class="nav-panel__link_lang" (click)="onChangeLanguage(lang)">
              {{lang}}
            </div>
          </li>
        </ul>
      </div>
      <!-- <a *ngIf="isAdminFlag" class="header-mobile__login">Log in</a> -->
    </div>

  </div>

</header>
