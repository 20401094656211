import { Component, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/lib/services/data/data.service';

@Component({
  selector: 'app-update-tag',
  templateUrl: './update-tag.component.html'
})
export class AppUpdateTagComponent {
    constructor(private dataService: DataService) {}


    baseEditView = true;

    @Input() tag: {};
    @Output() deleteTag: EventEmitter<any> = new EventEmitter<{id:number, tagName:string}>();
    @Output() updateTag: EventEmitter<any> = new EventEmitter<{id:number, tagName:string}>();

    onUpdateTag(tag) {
        this.updateTag.emit(tag);
        this.baseEditView = true;
    }

    onDelete(id:number) {
      let del = confirm("Do you want to delete tag?");
      if(del) {
        this.deleteTag.emit(id);
      }
    }

    isBaseMode(isEditMode: boolean) {
        this.baseEditView = isEditMode;
  
      }
}
