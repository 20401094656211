<div class="product-swiper">
<!-- <swiper-container
  navigation="true"
  space-between="10px"
  thumbs-swiper=".mySwiper2"
  class="swiper-active"
  loop="true"
  autoplay-delay="5000"
  watch-slides-progress="true"
  effect="coverflow"
  >
  <swiper-slide>
    <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
  </swiper-slide>
  <swiper-slide>
    <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
  </swiper-slide>
</swiper-container>
<swiper-container
    class="mySwiper2" 
    space-between="10" 
    slides-per-view="4" 
    free-mode="true"
    watch-slides-progress="true"
    watch-slides-visibility="true"
    class="swiper-thumb"
  >
  <swiper-slide>
    <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
  </swiper-slide>
  <swiper-slide>
    <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
  </swiper-slide>
  <swiper-slide>
    <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
  </swiper-slide>
</swiper-container> -->

<swiper #swiper [config]="config" [virtual]="true" class="swiper-active">
  <ng-template swiperSlide *ngFor="let image of imagesArr">
    <img [src]="image" />
  </ng-template>
  <!-- <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-1.jpg" />
  </ng-template>
  <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-2.jpg" />
  </ng-template>
  <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-3.jpg" />
  </ng-template>
  <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-4.jpg" />
  </ng-template> -->
</swiper>
<button  class="swiper-button-next" (click)="slideNext()"></button>
<button class="swiper-button-prev" (click)="slidePrev()"></button>
<swiper #swiper1 [config]="config1" class="swiper-thumb">
  <ng-template swiperSlide *ngFor="let image of imagesArr; let i = index">
    <img (click)="changeActiveSlide(i)" [src]="image" />
  </ng-template>
  <!-- <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-5.jpg" />
  </ng-template>
  <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-6.jpg" />
  </ng-template>
  <ng-template swiperSlide>
    <img src="https://swiperjs.com/demos/images/nature-7.jpg" />
  </ng-template>
  <ng-template swiperSlide>
    <img (click)="changeActiveSlide($event)" src="https://swiperjs.com/demos/images/nature-8.jpg" />
  </ng-template> -->
</swiper>


</div>
