import { Component } from '@angular/core';

@Component({
  selector: 'app-services-list-columns',
  templateUrl: './services-list-columns.component.html'
})
export class AppServicesListColumnsComponent {
  indexDiff1 = 1;
  indexDiff2 = 6;
}
