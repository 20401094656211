import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/lib/services/admin/admin.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class AppHeaderComponent implements OnInit{

  constructor(private adminService:AdminService, private router: Router) {
  }

  isAdminFlag = false;
  isLoggedIn = false;
  isChecked = false;

  ngOnInit(): void {
    // this.isAdminFlag = this.adminService.getIsAdminFlag();
    // this.isLoggedIn = this.adminService.getIsLoggedIn();


    this.adminService.isAdminFlag$.subscribe(isAdmin => {
      this.isAdminFlag  = isAdmin;
    });

    this.adminService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.adminService.updateInfoIsAdminFlag();
    this.adminService.updateInfoIsLoggedIn();

    
  }

  headerLinks = [
    {
      name: "navigation.nav-products",
      url: "/products",
      // subLinks: [
      //   {
      //     name: "navigation.used-loader",
      //     url: ""
      //   },
      //   {
      //     name: "navigation.add-equipment",
      //     url: ""
      //   },
      //   {
      //     name: "navigation.new-loader",
      //     url: ""
      //   }
      // ]
    },
    {
      name: "navigation.nav-services",
      url: "/services",
      // subLinks: [
      //   {
      //     name: "navigation.repair-service",
      //     url: "/services"
      //   },
        // {
        //   name: "navigation.service-manual",
        //   url: ""
        // },
        // {
        //   name: "navigation.forklift-rental",
        //   url: "/services/rent"
        // }
      // ]
    },
    {
      name: "navigation.nav-about-us",
      url: "/about-us",
      subLinks: [
        {
          name: "navigation.about-us",
          url: "/about-us"
        },
        // {
        //   name: "navigation.news",
        //   url: "/about-us/news"
        // },
        {
          name: "navigation.clients",
          url: "/about-us/clients"
        },
        // {
        //   name: "navigation.loading",
        //   url: "/about-us/loading"
        // },
        {
          name: "navigation.sell-us",
          url: "/about-us/sell-us"
        }
      ]
    },
    {
      name: "navigation.contacts",
      url: "/contacts",
    }
  ]

  headerLinksLoggedIn = [
    {
      name: "navigation.nav-products",
      url: "/products",
      subLinks: [
        {
          name: "navigation.create-product",
          url: "/create-product"
        },
        {
          name: "navigation.create-tag",
          url: "/create-tag"
        },
        {
          name: "navigation.logout",
          url: ""
        }
      ]
    }
  ]


  languages = ['en', 'pl'];

  isMenuOpen: boolean = false;

  isLangShown: boolean = false;

  onChangeLanguage(lang) {
    localStorage.setItem('lang', lang);
    location.reload();
  }

  onLangHover() {
    this.isLangShown = !this.isLangShown;
  }

  changeMenuOpen() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  logout() {
    this.adminService.clearAdminSessionData();
    this.router.navigate(['/']);
  }

  clickedOutside() {
    this.isMenuOpen = false;
    this.isChecked = false;
  }
}
