import { Component } from '@angular/core';

@Component({
  selector: 'app-service-rent',
  templateUrl: './service-rent.component.html'
})
export class AppServiceRentComponent {
  img = '../../../assets/image8.png';
  navLinks = [
    {
      url: "/services",
      name: "navigation.repair-service"
    },
    {
      url: "/services/rent",
      name: "navigation.forklift-rental"
    }
  ]
}
