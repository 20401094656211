<div class="login">
    <div class="login__wrapper">
        <h1 class="login__header">Login</h1>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="login-form">
            <label class="login-form__label" for="login">Login:</label>
            <input formControlName="login" class="login-form__input" type="text" id="login" placeholder="login...">
        
            <label class="login-form__label" for="password">Password:</label>
            <input formControlName="password" class="login-form__input" type="password" id="password" placeholder="password...">

            <div  *ngIf="isErrorMessageVisible" class="login-form__error">Oops! Incorrect login or password... Please, try again!</div>
        
            <button [disabled]="loginForm.status === 'INVALID'" class="login-form__button" type="submit">Login</button>
        </form>
    </div>
</div>