<div class="service-dropdown">
    <div class="service-dropdown__title">
        {{serviceItem.header}}
        <!-- <div class="icon service-dropdown__circle" [ngClass]="{'active':isDropdownOpen}">
            <div class="icon-shape" [ngClass]="{'active':isDropdownOpen}"></div>
        </div> -->
    </div>
    <ul class="service-dropdown__list" [ngClass]="{'active':isDropdownOpen}">
        <li 
            class="service-dropdown__list-item"
            *ngFor="let infoItem of serviceItem.info" 
            >{{infoItem}}
        </li>
    </ul>
</div>