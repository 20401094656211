<div class="clients">
    <div class="choice-details">
        <h2 class="clients__header">
            {{"about-us.for-clients-title1" | translate}}
        </h2>
        <div class="choice-details_bordered">
            {{"about-us.for-clients-p1" | translate}}
        </div>
        <div class="choice-details__img-block">
            <div class="choice-details__img">
                <app-bordered-product [imageSrc]="img"></app-bordered-product>
                <div class="choice-details__img-text">
                    {{"about-us.for-clients-p2" | translate}}
                </div>
            </div>
            <div class="choice-details__img">
                <app-bordered-product [imageSrc]="img"></app-bordered-product>
            </div>
        </div>
    </div>
    <div class="technical-details">
        <div class="technical-details__header">
            <h2 class="clients__header">{{"about-us.for-clients-title2" | translate}}</h2>
        </div>
        <div class="technical-details__block technical-details__block_bordered">
            {{"about-us.for-clients-p3" | translate}}
        </div>
        <div class="technical-details__block technical-details__block_green">
            <div
                *ngFor="let service of 'about-us.for-clients-p4' | translate" >
                ● {{service}}
            </div>        
        </div>
        <div class="technical-details__block technical-details__block_green">
            <div
                *ngFor="let service of 'about-us.for-clients-p5' | translate" >
                ● {{service}}
            </div>               
        </div>
    </div>
</div>