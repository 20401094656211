import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/lib/services/data/data.service';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AdminService } from 'src/app/lib/services/admin/admin.service';
import { FilterModalService } from '../modals/filter-modal.service';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html'
})
export class AppProductListComponent implements OnInit{

  constructor(private adminService:AdminService, private route: ActivatedRoute, private dataService: DataService, private router: Router, private filterModalService:FilterModalService) {}

  sortVariants = [
    {name: "sorting.price-asc", value: "price-asc", productValue: 'cost'},
    {name: "sorting.price-desc", value: "price-desc", productValue: 'cost'},
    {name: "sorting.year-asc", value: "year-asc", productValue: 'year'},
    {name: "sorting.year-desc", value: "year-desc", productValue: 'year'},
    {name: "sorting.new", value: "new", productValue: 'new'},
  ];

  sortOptions = {
    'price-asc': 'cost',
    'price-desc': 'cost',
    'year-asc': 'year',
    'year-desc': 'year',
    'new': 'new'
  }

  productList$ = new Subject<any>();

  selectedSort = {name: "sorting.new", value: "new", productValue: 'new'};

  isAdminFlag = false;
  isLoggedIn = false;

  isVariantsOpen = false;
  products = [];
  initialProducts = [];
  filteredProducts = [];
  tags: [];

  ngOnInit(): void {

    this.adminService.isAdminFlag$.subscribe(isAdmin => {
      this.isAdminFlag  = isAdmin;
    });

    this.adminService.isLoggedIn$.subscribe(isLoggedIn => {
      this.isLoggedIn = isLoggedIn;
    });

    this.productList$.subscribe( (res) => {
      this.products = res;
      this.initialProducts = res;
      this.filterProducts();
      this.sortProductList();
      }
    );

    this.dataService.getProducts().subscribe(productList => {
      this.productList$.next(productList.data.entities.reverse());

    });

    this.dataService.getTags().subscribe((data) => {
      this.tags = data.data.tags;
    });

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    )
      .subscribe(() => {
        this.filterProducts();
        this.sortProductList();
      });
    
  }

  sortProductList() {
    const sortOption = this.route.snapshot?.queryParams['sort'];
    if (sortOption === 'new') {
      this.products = this.filteredProducts;
    } else if (sortOption){
      const sortingField = this.sortOptions[sortOption];
      const order = sortOption.split('-')[1];
      this.selectedSort = this.sortVariants.filter(item => item.value === sortOption)[0];

      if (order === 'asc') {
        this.products.sort((a: any, b: any) => {
            if (a[sortingField] < b[sortingField]) {
              return -1;
            } else if (a[sortingField] > b[sortingField]) {
              return 1;
            } else {
              return 0;
            }
          });
      } else if (order === 'desc') {
        this.products.sort((a: any, b: any) => {
              if (a[sortingField] > b[sortingField]) {
                return -1;
              } else if (a[sortingField] < b[sortingField]) {
                return 1;
              } else {
                return 0;
              }
            });
      }
    }
  }

  onDeleteItem(id:number) {
    const updatedArr = this.products.filter(products => products.id !== id);
    this.productList$.next(updatedArr);
  }

  onEditItem(id:number) {
    this.router.navigateByUrl(`/edit-product/${id}`);
  }

  selectSort(url, index) {
    this.selectedSort = this.sortVariants[index];
    this.isVariantsOpen = false;
    this.router.navigate([], {queryParams: {"sort": url},  queryParamsHandling: 'merge'});
  }

  toggleVariantBlock() {
    this.isVariantsOpen = !this.isVariantsOpen;
  }

  clickedOutside() {
    this.isVariantsOpen = false;
  }

  openFilterModal() {
    this.filterModalService.isModalOpen$.next(true);
    this.filterModalService.disableBodyScroll();
  }

  filterProducts() {
    let filters = {};
    let liftHeightMin = this.route.snapshot?.queryParams['liftHeightMin'];
    let liftHeightMax = this.route.snapshot?.queryParams['liftHeightMax'];

    if (liftHeightMin || liftHeightMax) {
      liftHeightMin = liftHeightMin || 0;
      liftHeightMax = liftHeightMax || Infinity;

      filters['maxHeight'] = {
        value: [liftHeightMin, liftHeightMax],
        op: 'range'
      }      
    }

    let heightMin = this.route.snapshot?.queryParams['heightMin'];
    let heightMax = this.route.snapshot?.queryParams['heightMax'];

    if (heightMin || heightMax) {
      heightMin = heightMin || 0;
      heightMax = heightMax || Infinity;

      filters['height'] = {
        value: [heightMin, heightMax],
        op: 'range'
      }      
    }

    let yearMin = this.route.snapshot?.queryParams['yearMin'];
    let yearMax = this.route.snapshot?.queryParams['yearMax'];

    if (yearMin || yearMax) {
      yearMin = yearMin || 0;
      yearMax = yearMax || Infinity;

      filters['year'] = {
        value: [yearMin, yearMax],
        op: 'range'
      }      
    }

    let liftingCapacityMin = this.route.snapshot?.queryParams['liftingCapacityMin'];
    let liftingCapacityMax = this.route.snapshot?.queryParams['liftingCapacityMax'];

    if (liftingCapacityMin || yearMax) {
      liftingCapacityMin = liftingCapacityMin || 0;
      liftingCapacityMax = liftingCapacityMax || Infinity;

      filters['maxWeight'] = {
        value: [liftingCapacityMin, liftingCapacityMax],
        op: 'range'
      }      
    }

    if (this.route.snapshot?.queryParams['mastDuplex'] || this.route.snapshot?.queryParams['mastDuplexFree'] || this.route.snapshot?.queryParams['mastTriplex']) {
      const mastArr = [
        {
          name: 'mastDuplex',
          value: 'duplex'
        },
        {
          name: 'mastDuplexFree',
          value: 'duplex with free lift'
        },
        {
          name: 'mastTriplex',
          value: 'triplex with free lift'
        }
      ];
      let masts = [];
      for (let i=0; i<mastArr.length; i++) {
        let val = this.route.snapshot?.queryParams[mastArr[i].name] || null;
        if (val) {
          masts.push(mastArr[i].value);
        }
      }
      filters['mastType'] = {
        value: [...masts],
        op: 'eq'
      }  
    }

    if (this.route.snapshot?.queryParams['enginePetrol'] || this.route.snapshot?.queryParams['engineGas'] || this.route.snapshot?.queryParams['engineDiesel'] || this.route.snapshot?.queryParams['engineElectro']) {

      const engineArr = [
        {
          name: 'enginePetrol',
          value: 'gasoline'
        },
        {
          name: 'engineGas',
          value: 'lpg'
        },
        {
          name: 'engineDiesel',
          value: 'diesel'
        },
        {
          name: 'engineElectro',
          value: 'electro'
        }
      ];
      let engines = [];
      for (let i=0; i<engineArr.length; i++) {
        let val = this.route.snapshot?.queryParams[engineArr[i].name] || null;
        if (val) {
          engines.push(engineArr[i].value);
        }
      }
      filters['engine'] = {
        value: [...engines],
        op: 'inArray'
      }  
    }

    const isInArray = (value, filterValues) => {
      const valueArr = value?.split(',') || [];
      return filterValues?.some((fVal) => valueArr.includes(fVal)) || false;
    }

    const isEqual = (value, filterValue) => {
      if (filterValue.length) {
        return filterValue.includes(value);
      } else {
        if (value === undefined || value !== filterValue) {
          return false;
        }
      }
      return true;
    }
    
    const isInRange = (value, filterValue) => {
      const [startValue, endValue] = filterValue;
      if (value === undefined) {
        return false
      }
      if (value >= startValue && value <= endValue) {
        return true;
      }
      return false;
    }
    
    const filterObjects = (array, filterObj) => {
      return array.filter((item) => {
        const comparisonResult = [];
        for (const key in filterObj) {
          const { value: filterValue, op } = filterObj[key];
      
          if (op === 'eq') {
            comparisonResult.push(isEqual(item[key], filterValue));
          } else if (op === 'range') {
            comparisonResult.push(isInRange(item[key], filterValue));
          } else if (op === 'inArray') {
            comparisonResult.push(isInArray(item[key], filterValue))
          }
        }
        return comparisonResult.every(Boolean);
      });
    }

    this.products = filterObjects(this.initialProducts, filters);
    this.filteredProducts = this.products;
  }
  
}
