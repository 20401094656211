<div class="product-item__wrapper">
  <a class="product-item" *ngIf="product" [routerLink]="['/products', product.id]">
    <div class="product-item__img">
      <img [src]="image" alt="product-image">
    </div>
    <div class="product-item__info">
      <div class="product-item__info-category">
        <div  class="product-item__category">{{tags}}</div>
        <div class="product-item__info-img">
          <img src="./assets/svg-icons/heart-regular.svg" alt="like">
        </div>
      </div>
      <div class="product-item__info-text">
        <p>{{product.name}}</p>
        <p class="product-item__info-price">
          {{productCost}} {{isoValue}} 
        </p>
      </div>
    </div>
  </a>
  <div  class="product-item__overlay" *ngIf="isAdmin">
    <div class="product-item__icon edit" (click)="onEdit(product.id)">
      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"/></svg> 
    </div>
    <div class="product-item__icon cross" (click)="onDelete()">
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>                    
    </div>
  </div>
</div>

