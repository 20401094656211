<div class="service-columns">
    <div class="service-column">
        <div class="service-column__wrapper1">
            <div class="service-column__header">
                {{"services.services-checkup.header" | translate}}
            </div>
            <app-service-dropdown 
                *ngFor="let serviceItem of 'services.services-checkup.checkup' | translate" 
                [serviceItem]="serviceItem"
            ></app-service-dropdown>
        </div>
    </div>
    <div class="service-column">
        <div class="service-column__wrapper2">
            <div class="service-column__header">
                {{"services.services-repair.header" | translate}}
            </div>
            <app-service-dropdown
                *ngFor="let serviceItem of 'services.services-repair.repair' | translate" 
                [serviceItem]="serviceItem"
            ></app-service-dropdown>
        </div>
    </div>
</div>