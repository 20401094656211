<div class="breadcrumbs">
    <a class="breadcrumb" [routerLink]="['/products']">
        <img src="../../../assets/svg-icons/house.svg" alt="house">
        <span>></span>
    </a>
    <a 
        *ngFor="let breadcrumb of breadcrumbsArr; let i = index"  
        class="breadcrumb"
        [routerLink]="breadcrumb.url"
        >
        {{breadcrumb.text | translate}}
        <span>></span>
    </a>
</div>