import { DataService } from 'src/app/lib/services/data/data.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';


@Component({
  selector: 'app-product-item',
  templateUrl: './product-item.component.html'
})
export class AppProductItemComponent implements OnInit{

  @Input() product;
  @Input() isAdmin;
  @Output() deleteProductItem = new EventEmitter<number>();
  @Output() editProductItem = new EventEmitter<number>();

  imagesArr = [];
  productCost;
  isoValue = 'PLN';

  image;

  tags;

  constructor(private dataService: DataService) {}

  ngOnInit(): void {
    if (this.product?.images) {
      this.imagesArr = this.product.images.split(";");
        this.image = this.imagesArr[0];
    }
    else {
      this.image = './assets/default.jpg';
    }

    this.tags =this.product.entities_tags.map(({tag}) => tag.tagName).join(',  ');

    this.productCost = this.product.cost;
    const currentLang = localStorage.getItem('lang');
    if (currentLang === 'en') {
      this.isoValue = 'EUR';
      const currency  = Number(localStorage.getItem('currency'));
      this.productCost = Math.ceil(this.product.cost * currency);
    }
 
  }
  
  onDelete() {
    let del = confirm("Do you want to delete item?");

    if(del) {
      this.dataService.deleteProduct(this.product.id).subscribe((data) => {
        const id = data.data.delete_entities_by_pk.id;
        this.deleteProductItem.emit(id);
      });
    }
  }

  onEdit(id:number) {
    this.editProductItem.emit(id);
  }
}
