<div class="service-rent">
    <div class="service-rent__info">
        <app-nav-bar [links]="navLinks"></app-nav-bar>
        <div class="service-rent__info-section">
            <div class="service-rent__info-title">
                Аренда вилочных погрузчиков от 1 до 9 тонн, разгрузка с оператором. 
            </div>
            <div class="service-rent__info-subtitle">
                Длительная аренда, доставка вилочного погрузчика 
                к вам, при длительной аренде скидки до 20% ! Всегда готовы обсуждать цену вопроса. 
            </div>
            <a class="service-rent__info-phone" href="tel:+4733378901">Звоните +375(29)181-55-58</a>
        </div>
    </div>
    <div class="service-rent__img">
        <app-bordered-product [imageSrc]="img"></app-bordered-product>
    </div>
</div>
<div class="service-rent__text-title">
    Данная статья посвящена аренде вилочных погрузчиков 
    и в ней мы попытаемся рассказать преимущества и недостатки данной операции, а также некоторые нюансы этой сделки.
</div>
<div class="service-rent__text">
    <p>
        Первым делом коснемся рентабельности. Как и везде в бизнесе этот аспект занимает главное место. Здесь хотелось бы в общих чертах сравнить смысл приобретения и аренды погрузчика. С учетом сложившихся тарифов, насыщенности рынка, цен на погрузчики , можно с уверенностью сказать, что наиболее выгодна на небольшой срок, это краткосрочная аренда погрузчика. 
        К примеру, у Вас сломался собственный погрузчик, а ведение деятельности без него невозможно. Очевидно, что в данной ситуации удобно, разумно и очень выгодно арендовать погрузчик. Ведь убытки от простоя вашего предприятия будут значительно больше цены арендной платы. Если Ваше предприятие рентабельно, естественно. Кроме того аренда в данной ситуации позволит сохранить лицо 
        и не потерять клиентов. Это, конечно нельзя оценить финансово, но, как правило, 
        в дальнейшем окупится вдвойне. Вскоре ремонт Вашего погрузчика закончиться
         и Вы сможете забыть эту неприятность. До следующей неожиданной поломки. Немного отклоняясь от темы , хотелось бы заметить, что вероятность неожиданных , внеплановых ремонтов можно свести к минимуму своевременно и качественно диагностируя состояние Вашего погрузчика. Ведь если вовремя обнаружить приближение неисправности, можно заранее заказать необходимые запасные части и произвести ремонт не спеша, качественно , быстро и в удобное для Вас время. Обратная ситуация – »внезапная » поломка, остановка погрузчика. 
        И стоимость срочного ремонта зачастую окажется выше. Не считая потраченных нервов и времени.
    </p>
    <p>
        Тем не менее вернемся к аренде. Довольно часто встречается ситуация, когда 
        Вы пользуетесь погрузчиком не часто. К примеру, Вам нужно разгрузить одну – две фуры в месяц. В данном случае также разумнее взять погрузчик в аренду. Ведь Вам не придется думать не о ремонте, не о техническом обслуживании , хранении погрузчика. Так же отпадает необходимость в содержании оператора погрузчика. Ведь один раз в месяц его функции может выполнить кладовщик или любой рабочий, получивший удостоверение на право управления погрузчиком. Или 
        без него, но это уже на Ваше усмотрение. Заметим еще один немаловажный аспект. Кроме естественного износа существует еще потеря стоимости погрузчика 
        с течением времени. Ведь в любом случае, к моменту продажи Ваш погрузчик старее и дешевле чем в момент его покупки. Иногда значительно. Так же Вам 
        не придется заниматься процессом покупки и, рано или поздно продажей автопогрузчика. Именно поэтому многие фирмы выбирают аренду. Разумеется, если Вам необходим вилочный погрузчик достаточно часто, то чаша весов склониться к покупке погрузчика.
    </p>
    <p>
        Есть нюансы, от которых хотелось бы вас предостеречь. Ведь беря погрузчик 
        в аренду очень часто люди относятся к нему неаккуратно, небрежно. Вероятный результат – следующий клиент, возможно, Вы, возьмет в аренду погрузчик, который преподнесет сюрприз — сломается. Хорошо, что это встречается не часто 
        и в большей степени относится к старым болгарским и советским погрузчикам. Однако в данном случае стоит учесть, что если Вы заказали услугу по доставке погрузчика в одной фирме, а аренду в другой, и произошла поломка погрузчика, 
        то вы получаете расходы по транспортировке погрузчика, потраченное время, 
        не сделанную работу и плохое настроение. Частично избежать этого можно , если услуги по доставке и аренде оказывает одна фирма. Ведь никто не захочет рисковать, сдавая в аренду » металлолом» и бесплатно перевозя его в случае поломки. И Вам, хоть и немного, будет легче морально и материально: не придется оплачивать за свой счет доставку техники, которая так и не смогла Вам помочь 
        и доставила еще одну головную боль. Разумеется, на 100% избежать этого 
        не возможно, но свести риск к минимуму можно. Старайтесь арендовать качественную технику и получать услугу комплексно.
    </p>
</div>