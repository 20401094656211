import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AdminService } from 'src/app/lib/services/admin/admin.service';
import { DataService } from 'src/app/lib/services/data/data.service';

@Component({
  selector: 'app-login-page',
  templateUrl: './login.component.html'
})
export class AppLoginPageComponent implements OnInit{

  constructor(private dataService: DataService, private adminService: AdminService, private router: Router) {}
  
  loginForm: UntypedFormGroup;
  isErrorMessageVisible = false;


  ngOnInit(): void {
    this.loginForm = new UntypedFormGroup({
      login: new UntypedFormControl(null, Validators.required),
      password: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[a-zA-Z0-9!]*$/)])
    });
  }


  onSubmit() {
    const controls = this.loginForm.controls;

    this.dataService.getItem(controls['login'].value, controls['password'].value).subscribe(res => {
      const userData = res.data.users;
      if(res.data.users.length) {
        this.adminService.setIsLoggedIn(userData[0].username);
        this.adminService.setIsLoggedInObs(true);
        this.router.navigate(['/']);
        
      } else {
        this.isErrorMessageVisible = true;
        this.loginForm.reset();
      }
    });
  }
}
