<footer class="footer">
    <div class="footer-section">
      <div class="footer-section__title">
        {{ footerNavigation.aboutUs.header | translate}}
      </div>
      <p class="footer-section__info">
        {{ footerNavigation.aboutUs.text | translate}}

      </p>
    </div>
    <div class="footer-section">
      <div class="footer-section__title">
        {{ footerNavigation.navigation.header | translate}}
      </div>
      <div class="footer-section__links">
        <a *ngFor="let link of footerNavigation.navigation.links" [routerLink]="link.url">
          {{link.name | translate}}
        </a>
      </div>

    </div>
    <div class="footer-section">
      <div class="footer-section__title">
        {{ footerNavigation.contacts.header | translate}}
      </div>
      <div class="footer-section__contacts">
        <p>
          <span>{{ footerNavigation.contacts.phone.name | translate}}:</span>
          <a href="tel:+48515499875"> {{footerNavigation.contacts.phone.value | translate}}</a>
        </p>
        <p>
          <span>{{ footerNavigation.contacts.email.name | translate}}:</span>
          <a href = "mailto:zoomwozki@gmail.com"> {{footerNavigation.contacts.email.value | translate}}</a>
        </p>
      </div>

    </div>
</footer>
