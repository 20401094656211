import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from 'src/app/lib/services/data/data.service';
import { FilterModalService } from '../filter-modal.service';

@Component({
  selector: 'app-filter-modal',
  templateUrl: './filter-modal.component.html'
})
export class AppFilterModalComponent implements OnInit{
  

  constructor(private dataService: DataService, private route: ActivatedRoute, private filterModalService:FilterModalService, private router: Router ) {
  }

  @ViewChild('formFilter') formElement; 

  currentYear;

  initValues = {
    liftHeightMin : 1,
    liftHeightMax : 7.5,
    heightMin : 1.5,
    heightMax : 4,
    yearMin : 1970,
    liftingCapacityMin : 0.5,
    liftingCapacityMax : 10,
  }


  filterForm: FormGroup;

  isFilterOpen = [false, false, false, false, false, false]; 

  ngOnInit(): void {
    this.currentYear =new Date().getFullYear();

    this.filterForm = new FormGroup({
      liftHeightMin: new FormControl(this.route.snapshot?.queryParams['liftHeightMin'] || this.initValues.liftHeightMin),
      liftHeightMax: new FormControl(this.route.snapshot?.queryParams['liftHeightMax'] || this.initValues.liftHeightMax),
      heightMin: new FormControl(this.route.snapshot?.queryParams['heightMin'] || this.initValues.heightMin),
      heightMax: new FormControl(this.route.snapshot?.queryParams['heightMax'] || this.initValues.heightMax),
      yearMin: new FormControl(this.route.snapshot?.queryParams['yearMin'] || this.initValues.yearMin),
      yearMax: new FormControl(this.route.snapshot?.queryParams['yearMax'] || this.currentYear),
      liftingCapacityMin: new FormControl(this.route.snapshot?.queryParams['liftingCapacityMin'] || this.initValues.liftingCapacityMin),
      liftingCapacityMax: new FormControl(this.route.snapshot?.queryParams['liftingCapacityMax'] || this.initValues.liftingCapacityMax),
      enginePetrol: new FormControl(this.route.snapshot?.queryParams['enginePetrol'] || false),
      engineGas: new FormControl(this.route.snapshot?.queryParams['engineGas'] || false),
      engineDiesel: new FormControl(this.route.snapshot?.queryParams['engineDiesel'] || false),
      engineElectro: new FormControl(this.route.snapshot?.queryParams['engineElectro'] || false),
      mastDuplex: new FormControl(this.route.snapshot?.queryParams['mastDuplex'] || false),
      mastDuplexFree: new FormControl(this.route.snapshot?.queryParams['mastDuplexFree'] || false),
      mastTriplex: new FormControl(this.route.snapshot?.queryParams['mastTriplex'] || false)
    });
  }

  closeFilterModal() {
    this.filterModalService.isModalOpen$.next(false);
    this.filterModalService.enableBodyScroll();
  }

  toggleFilterOpen(i:number) {
    this.isFilterOpen[i] = !this.isFilterOpen[i];
  }

  clickOnOverlay(event) {
    const target = event.target;
    const formElement = this.formElement.nativeElement;
    const isOutside = !(target === formElement || formElement.contains(target));
    if (isOutside) {
      this.closeFilterModal();
    }
  }

  onSubmit() {
    const queryObj = Object.entries(this.filterForm.controls).reduce((acc, [key, { value }]) => {
      if (!value) {
        return acc;
      }
      return { ...acc, [key]: value };
    }, {});

    this.router.navigate(
      [],
      { queryParams:queryObj },
      
    );
    this.closeFilterModal();
  }

  clearFilters() {
    this.router.navigate(
      []      
    );
    this.closeFilterModal();
  }
}
