import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {AppContactsPageComponent} from "./pages/contacts/contacts.component";
import {AppServiceRepairPageComponent} from "./pages/service-repair/service-repair.component";
import {AppProductListPageComponent} from "./pages/product-list-page/product-list-page.component";
import {AppServiceRentPageComponent} from "./pages/service-rent/service-rent-page.component";
import {AppProductDetailsPageComponent} from "./pages/product-details-page/product-details-page.component";
import {AppClientsComponent} from "./components/info/clients/clients.component";
import {AppAboutUsComponent} from "./components/info/about-us/about-us.component";
import {AppNewsComponent} from "./components/info/news/news.component";
import {AppBatchComponent} from "./components/info/batch/batch.component";
import {AppSellUsComponent} from "./components/info/sell-us/sell-us.component";
import {AppAboutUsPageComponent} from "./pages/about-us/about-us.component";
import {AppServicesPageComponent} from "./pages/services/services.component";
import { AppLoginPageComponent } from './pages/login/login.component';
import { AppCreateProductPageComponent } from './pages/create-product/create-product.component';
import { AdminGuard } from './lib/guards/admin.guard';
import { AuthGuard } from './lib/guards/auth.guard';

import { AppCreateTagPageComponent } from './pages/create-tag/create-tag.component';
import { AppEditProductPageComponent } from './pages/edit-product/edit-product.component';



const routes: Routes = [
  { path: 'contacts', component: AppContactsPageComponent },
  { path: 'services', component: AppServicesPageComponent,
      children: [
        {
          path: 'rent',
          component: AppServiceRentPageComponent
        },
        {
          path: '**',
          component: AppServiceRepairPageComponent
        }

      ]
    },
  { path: '', redirectTo: '/products', pathMatch: 'full' },
  { path: 'products', component: AppProductListPageComponent },
  { path: 'products/:id', component: AppProductDetailsPageComponent },
  { path: 'edit-product/:id', component: AppEditProductPageComponent, canActivate: [AdminGuard] },
  // { path: 'product-details', component: AppProductDetailsPageComponent },
  { path: 'login', component: AppLoginPageComponent, canActivate: [AuthGuard] },
  { path: 'create-product', component: AppCreateProductPageComponent, canActivate: [AdminGuard] },
  { path: 'create-tag', component: AppCreateTagPageComponent, canActivate: [AdminGuard] },
  { path: 'about-us', component: AppAboutUsPageComponent,
      children: [
        {
          path: 'clients',
          component: AppClientsComponent
        },
        // {
        //   path: 'news',
        //   component: AppNewsComponent
        // },
        {
          path: 'loading',
          component: AppBatchComponent
        },
        {
          path: 'sell-us',
          component: AppSellUsComponent
        },
        {
          path: '**',
          component: AppAboutUsComponent
        }

      ]
    },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
