<ng-container>
    <div class="product-details" *ngIf="product">
        <div class="product-details__carousel">
            <app-product-swiper [images]="product.images"></app-product-swiper>
        </div>
        <div class="product-details__info">
            <div class="product-details__info-name">
                {{product.name}}
            </div>
            <div class="product-details__info-price">
                {{productPrice}} {{selectedCurrency.symbol}}
            </div>
            <div class="product-details__info-text">
                {{"product-details.capacity" | translate}}: {{product.maxWeight}} t
            </div>
            <div class="product-details__info-text">
                {{"product-details.height" | translate}}: {{product.maxHeight}} m
            </div>
            <div class="product-details__info-text">
                {{"product-details.engine" | translate}}:
                <span *ngFor="let engine of engineArr">
                    {{engineTranslations[engine] | translate}}
                </span>
            </div>
            <div class="product-details__info-text">
                {{"filtering.height" | translate}}: {{product.height}} m
            </div>
            <div class="product-details__info-text">
                {{"product-details.gearBox" | translate}}: {{product.transmission}}
            </div>
            <div class="product-details__info-text">
                {{"filtering.mast" | translate}}: {{mastTranslations[product.mastType] | translate}}
            </div>
            <div class="product-details__info-text">
                {{"filtering.year" | translate}}: {{product.year}}
            </div>
            <div *ngIf="product?.equipment && product?.equipment !== 'null'" class="product-details__info-text">
                {{"product-details.equipment" | translate}}: {{product.extraFeatures}}
            </div>
            <div class="product-details__currency" (click)="onDropdownClick()">
                <div class="product-details__currency-value">
                    {{selectedCurrency.name}}
                </div>
                <div class="product-details__currency-dropdown" [ngClass]="{'active':isDropdownOpen}">
                    <div *ngFor="let curr of currencies; let i=index" class="product-details__currency-dropdown-value" (click)="changeCurrency(i)">
                        {{curr.name}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="product?.description && product?.description !== 'null'" class="product-details__descr">
        <div class="product-details__descr-title">
            {{"product-details.info" | translate}}
        </div>
        {{product.description}}
    </div>
</ng-container>
