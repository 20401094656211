import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-service-dropdown',
  templateUrl: './service-dropdown.component.html'
})
export class AppServiceDropdownComponent {

  @Input() serviceItem : any;

  isDropdownOpen: boolean = true;
}
