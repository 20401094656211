<div class="service-list-columns">
    <div class="service-list-columns__title">
        {{'services.service-repair.header' | translate}}
    </div>
    <div class="service-list-columns__wrapper">
        <div class="service-list-columns__column">
            <app-services-list-item 
                *ngFor="let service of 'services.service-repair.services_1' | translate; let i = index" 
                [serviceText]="service"
                [index]="i + indexDiff1">
            </app-services-list-item>

        </div>
        <div class="service-list-columns__column">
            <app-services-list-item 
                *ngFor="let service of 'services.service-repair.services_2' | translate; let i = index" 
                [serviceText]="service"
                [index]="i + indexDiff2">
            </app-services-list-item>
        </div>
    </div>
</div>
