import { Component } from '@angular/core';

@Component({
  selector: 'app-service-rent-page',
  templateUrl: './service-rent-page.component.html'
})
export class AppServiceRentPageComponent {
  title = "feedback-form.title-service-repair";

}
