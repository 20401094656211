import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-services-list-item',
  templateUrl: './services-list-item.component.html'
})
export class AppServicesListItemComponent implements OnInit{
  @Input() serviceText = '';
  @Input() index;
  imgPath = "";

  ngOnInit(): void {
    this.imgPath = `../../../assets/svg-icons/service-repair_${this.index}.svg`
  }

}
