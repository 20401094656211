import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class AppFooterComponent {
  footerNavigation = {
    aboutUs: {
      header: "navigation.nav-about-us",
      text: "navigation.footer-about-us"
    },
    navigation: {
      header: "navigation.navigation",
      links: [
        {
          name: "navigation.catalog",
          url: "/products",
        },
        {
          name: "navigation.repair-service",
          url: "/services"
        },
        // {
        //   name: "navigation.forklift-rental",
        //   url: "services/rent"
        // },
        {
          name: "navigation.about-us",
          url: "/about-us"
        },
        {
          name: "navigation.contacts",
          url: "/contacts",
        }
      ]
    },
    contacts: {
      header: "navigation.contacts",
      phone: {
        name: "contacts.phone",
        value: "contacts.phone-value"
      },
      email: {
        name: "contacts.email",
        value: "contacts.email-value"
      }
    }
  }
}
