import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataService } from 'src/app/lib/services/data/data.service';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html'
})
export class AppProductDetailsComponent implements OnInit {

  constructor(private dataService: DataService, private route: ActivatedRoute) {}

  isDropdownOpen: boolean = false;
  product;
  productId = this.route.snapshot.params['id'];
  imagesArr = [];
  currencies = [
    {
      name: "Polski złoty (zł) - PLN",
      symbol: "PLN",
      isoValue: "PLN"
    },
    {
      name: "Euro (€) - EUR",
      symbol: "€",
      isoValue: "EUR"
    }
  ];
  engineTranslations = {
    gasoline: "filtering.engine-petrol",
    lpg: "filtering.engine-gas",
    diesel: "filtering.engine-diesel",
    electro: "filtering.engine-electro"
  }
  mastTranslations = {
    'triplex with free lift' : 'filtering.mast-triplex',
    'duplex with free lift' : 'filtering.mast-duplexFree',
    'duplex' : 'filtering.mast-duplex'
  }
  selectedCurrency = this.currencies[0];
  productPrice;
  engineArr = [];



  ngOnInit(): void {
    this.dataService.getProductById(this.productId).subscribe(product => {
      this.product = product.data.entities_by_pk;
      this.productPrice = this.product.cost;
      this.engineArr = this.product.engine.split(',');
      const currentLang = localStorage.getItem('lang');
      if (currentLang === 'en') {
        this.changeCurrency(1);
      }
    });
  }

  onDropdownClick() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  changeCurrency(i:number) {
    const prevCurr = this.selectedCurrency.isoValue;
    this.selectedCurrency = this.currencies[i];
    if(i === 0) {
      this.productPrice = this.product.cost;
    } else {
      this.dataService.getProductPrice(prevCurr, this.selectedCurrency.isoValue, this.productPrice.toString().replaceAll("~","") ).subscribe((res) => {
        this.productPrice = '~' + Math.ceil(res.rates[this.selectedCurrency.isoValue]);
    
      });
    }

  }

}
