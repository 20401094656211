import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { AdminService } from "../services/admin/admin.service";
import { Observable } from "rxjs";
import { map, take, tap, withLatestFrom } from "rxjs/operators";

@Injectable({providedIn: 'root'})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private adminService: AdminService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        return this.adminService.isAdminFlag$.pipe(
            take(1),
            withLatestFrom(this.adminService.isLoggedIn$),
            map(([flag, loggedIn]) => {
                const isAdminFlag = !!flag;
                const isLoggedIn = !!loggedIn;
                if (isAdminFlag && !isLoggedIn) {
                    return true;
                }

                return this.router.createUrlTree(['/products']);
            })
        );
    }
}