import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AdminService } from './lib/services/admin/admin.service';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { FilterModalService } from './components/modals/filter-modal.service';
import { DataService } from './lib/services/data/data.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'zoomlion';
  isModalOpen = false;
  isModalOpen$ = new Subject<any>();

  constructor(private translateService: TranslateService, private dataService: DataService, private route:ActivatedRoute, private adminService: AdminService, private filterModalService:FilterModalService ) {
    this.translateService.setDefaultLang('pl');
    this.translateService.use(localStorage.getItem('lang') || 'pl');
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(queryParams => {

      if(queryParams['admin'] === 'true') {
        this.adminService.setIsAdminFlag();
        this.adminService.setIsAdminFlagObs(true);
      }
    }); 

    this.filterModalService.isModalOpen$.subscribe( isOpen => {
      this.isModalOpen = isOpen;
    });

    const currentCurrency = localStorage.getItem('lang');
    if(currentCurrency === 'en') {
      this.dataService.getProductPrice('PLN', 'EUR', 1 ).subscribe((res) => {
        localStorage.setItem('currency', res.rates['EUR']);
      });
    }


  }

}
