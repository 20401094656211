import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { DataService } from 'src/app/lib/services/data/data.service';

@Component({
  selector: 'app-form-req',
  templateUrl: './form-req.component.html'
})
export class AppFormReqComponent implements OnInit {
  @Input() title;

  constructor(private dataService: DataService) {}

  @ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;


  isErrorMessageVisible = false;
  isProblemsMessageVisible = false;
  isSuccessMessageVisible = false;


  reqForm: FormGroup;

  ngOnInit(): void {
    this.reqForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      email: new FormControl(null, Validators.required),
      phone: new FormControl(null, Validators.required),
      subject: new FormControl(null, Validators.required),
      body: new FormControl(null, Validators.required),
    });
  }


  onSubmit() {

    const controls = this.reqForm.controls;
    this.isErrorMessageVisible = false;
    this.isProblemsMessageVisible = false;

    if(this.reqForm.valid) {
      this.dataService.sendEmail(controls).subscribe((res) => {
        const responseStatus = res?.data?.sendMail?.Status;
        if(responseStatus == "success") {
          this.isSuccessMessageVisible = true;
          this.formGroupDirective.resetForm();
          setTimeout(() => {
            this.isSuccessMessageVisible = false;

          }, 4000);

        } else {
          this.isProblemsMessageVisible = true;
        }
      });
    } else {
      this.isErrorMessageVisible = true;
    }
  }
}
