import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { ImagekitioAngularModule } from 'imagekitio-angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {AppHeaderComponent} from "./components/header/header.component";
import {AppFooterComponent} from "./components/footer/footer.component";
import {AppNavBarComponent} from "./components/nav-bar/nav-bar.component";
import { AppServiceDropdownComponent } from './components/service-dropdown/service-dropdown.component';
import { AppServiceColumnsComponent } from './components/service-columns/service-columns.component';
import { AppServiceTextComponent } from './components/service-text/service-text.component';
import { AppServiceRepairPageComponent } from './pages/service-repair/service-repair.component';
import { AppServicesListColumnsComponent } from './components/services-list-columns/services-list-columns.component';
import { AppServicesListItemComponent } from './components/services-list-item/services-list-item.component';
import {AppFormReqComponent} from "./components/form-req/form-req.component";
import {AppContactsComponent} from "./components/contacts/contacts.component";
import {AppContactsPageComponent} from "./pages/contacts/contacts.component";
import {AppProductItemComponent} from "./components/product-item/product-item.component";
import {AppProductListComponent} from "./components/product-list/product-list.component";
import {AppProductListPageComponent} from "./pages/product-list-page/product-list-page.component";
import {AppServiceRentComponent} from "./components/service-rent/service-rent.component";
import {AppServiceRentPageComponent} from "./pages/service-rent/service-rent-page.component";
import {AppProductDetailsComponent} from "./components/product-details/product-details.component";
import {AppProductDetailsPageComponent} from "./pages/product-details-page/product-details-page.component";
import {AppProductSwiperComponent} from "./components/product-swiper/product-swiper.component";
import {AppAboutUsComponent} from "./components/info/about-us/about-us.component";
import {AppClientsComponent} from "./components/info/clients/clients.component";
import {AppNewsComponent} from "./components/info/news/news.component";
import {AppBatchComponent} from "./components/info/batch/batch.component";
import {AppSellUsComponent} from "./components/info/sell-us/sell-us.component";
import {AppAboutUsPageComponent} from "./pages/about-us/about-us.component";
import {AppBorderedProductComponent} from "./components/bordered-product/bordered-product.component";
import {AppServicesPageComponent} from "./pages/services/services.component";
import {AppBreadcrumbsComponent} from "./components/breadcrumbs/breadcrumds.component";






import {SwiperModule} from 'swiper/angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AppLoginPageComponent } from './pages/login/login.component';
import { AppCreateProductPageComponent } from './pages/create-product/create-product.component';
import { AppCreateTagPageComponent } from './pages/create-tag/create-tag.component';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// import { register } from 'swiper/element/bundle';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AppUpdateTagComponent } from './components/update-tag/update-tag.component';
import { AppEditProductPageComponent } from './pages/edit-product/edit-product.component';

import { ClickOutsideDirective } from './lib/directives/clickOutside.directive'
import { AppFilterModalComponent } from './components/modals/filter-modal/filter-modal.component';

// register();

import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatCheckboxModule} from '@angular/material/checkbox';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ArraySortPipe } from './lib/pipes/sort.pipe';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    AppNavBarComponent,
    AppServiceDropdownComponent,
    AppServiceColumnsComponent,
    AppServiceTextComponent,
    AppServiceRepairPageComponent,
    AppServicesListColumnsComponent,
    AppServicesListItemComponent,
    AppFormReqComponent,
    AppContactsComponent,
    AppContactsPageComponent,
    AppProductItemComponent,
    AppProductListComponent,
    AppProductListPageComponent,
    AppServiceRentPageComponent,
    AppServiceRentComponent,
    AppProductDetailsComponent,
    AppProductDetailsPageComponent,
    AppProductSwiperComponent,
    AppAboutUsComponent,
    AppClientsComponent,
    AppNewsComponent,
    AppBatchComponent,
    AppSellUsComponent,
    AppAboutUsPageComponent,
    AppBorderedProductComponent,
    AppServicesPageComponent,
    AppBreadcrumbsComponent,
    AppLoginPageComponent,
    AppCreateProductPageComponent,
    AppCreateTagPageComponent,
    AppUpdateTagComponent,
    AppEditProductPageComponent,
    ClickOutsideDirective,
    AppFilterModalComponent,
    ArraySortPipe,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SwiperModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    NgMultiSelectDropDownModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImagekitioAngularModule.forRoot({
      publicKey: 'public_ijMfr/630Erzm4G24BmZibEKLkE=',
      urlEndpoint: 'https://ik.imagekit.io/zoomlion/',
      authenticationEndpoint: 'https://understood-prism-schooner.glitch.me/auth'
    }),
    BrowserAnimationsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
