import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
// import { catchError } from 'rxjs/operators';


@Injectable({
  providedIn: 'root',
})
export class DataService {

    constructor(private router:Router, private http: HttpClient) {
    }

    httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json',
          'x-hasura-admin-secret': 'odoOVOmDwxxp2Ln7sTmQGdkoxfhoRh3n4F4JuSP1Pkf0ZJ1DTf4024i2qRi4E2rQ'
        })
      };

      // httpOptions1 = {
      //   headers: new HttpHeaders({
      //     'X-Api-Key': 'wfijRi7a4SXmOS/oj/iSYw==5ZRPOeTwGljKitFx'
      //   })
      // };

      mainUrl = 'https://amusing-chimp-27.hasura.app/v1/graphql'


    getItem(username: string, password: string):Observable<any> {

      const query ={
        "query": "query GetUser($username: String, $password: String) { users(where: { username: { _eq: $username }, password: { _eq: $password } }) { id, username } }",
        "variables": { "username": username, "password": password }
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    getTags():Observable<any> {

      const query ={
        "query": "query GetTags { tags {id, tagName} }"
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    addTag(tagName: string): Observable<any> {

      const query ={
        "query": `mutation addTag { insert_tags_one(object: {tagName:"${tagName}"}){id, tagName} }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    deleteTag(id: number):Subscription {

      const query ={"query": `mutation deleteTag { delete_tags_by_pk(id: ${id}) { id tagName }}`};

      return this.http.post(this.mainUrl, query, this.httpOptions).subscribe();
    }

    updateTag(tag):Observable<any> {

      const query ={"query": `mutation updateTag {update_tags_by_pk(pk_columns: {id: ${tag.id}}, _set: {tagName:  "${tag.tagName}"}) {id tagName }}`};

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    addProduct(controls: any, engine: any = '', mast: any = '', status: any = '', imagesArr:any = null): Observable<any> {


      const images = imagesArr.length ? JSON.stringify(imagesArr.join(';')).toString() : null;
      let engineValues = [];
      for (let i=0; i<engine.length; i++) {
        engineValues.push(engine[i].engineName);
      }

      const newProduct ={
        cost:controls['price'].value,
        description: controls['extraInformation']?.value || null,
        engine: engine.length > 1 ?  engineValues.join(',') : engine[0]?.engineName,
        extraFeatures: controls['optionalEquipment']?.value || null,
        height: controls['height'].value,
        maxHeight: controls['maxHeight'].value,
        maxWeight: controls['weight'].value,
        name: controls['name'].value,
        status: status[0]?.statusName || null,
        transmission: controls['transmission'].value,
        mastType: mast[0]?.mastName || null,
        year: controls['year'].value,
      }

      const query ={
        "query": `mutation addProduct {
          insert_entities_one(object: {cost: "${newProduct.cost}", description: "${newProduct.description}", year: "${newProduct.year}", engine: "${newProduct.engine}",  mastType: "${newProduct.mastType}", extraFeatures: "${newProduct.extraFeatures}", height: "${newProduct.height}", maxHeight: "${newProduct.maxHeight}", maxWeight: "${newProduct.maxWeight}", name: "${newProduct.name}", status: "${newProduct.status}", images: ${images}, transmission: "${newProduct.transmission}"}) {
            id
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    getProducts(): Observable<any> {

      const query ={
        "query": `query getAllItems {
          entities {
            cost
            description
            engine
            extraFeatures
            id
            maxHeight
            maxWeight
            name
            status
            images
            mastType
            transmission
            year
            height
            entities_tags {
              tag {
                id
                tagName
              }
            }
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    getProductById(id:number): Observable<any> {

      const query ={
        "query": `query getItemById{
          entities_by_pk(id: ${id}) {
            cost
            description
            engine
            extraFeatures
            id
            maxHeight
            height
            year
            mastType
            maxWeight
            name
            status
            transmission
            images
            entities_tags {
              tag {
                id
                tagName
              }
            }
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    assignTagsToProduct(tags): Observable<any> {
      const tagsString = tags.reduce((acc, obj) => {
        const objString = `{ entityId: ${obj.entityId}, tagId: ${obj.tagId} }`;
        return [...acc, objString];
      }, []).join(', ');
      const query = {
        "query": `mutation assignTagToItem { insert_entities_tags(objects: [${tagsString}]) { returning { id } }}`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    deleteTagsToProduct(id, tags): Observable<any> {
      
      const query = {
        "query": `mutation deleteTagsFromEntity {
          delete_entities_tags(where: {entityId: {_eq: ${id}}, _and: {tagId: {_in: [${[...tags]}]}}}) {
            returning {
              id
            }
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }


    deleteProduct(id:number): Observable<any> {

      const query ={
        "query": `mutation deleteProduct {
          delete_entities_by_pk(id: ${id}) {
            id
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    sendEmail(controls: any): Observable<any> {

      const newEmail ={
        name:controls['name']?.value,
        email: controls['email']?.value,
        phone: controls['phone']?.value,
        subject: controls['subject']?.value,
        body: controls['body']?.value,
      }

      const query ={
        "query": `query sendEmail {
          sendMail(name: "${newEmail.name}", phone: "${newEmail.phone}", body: "${newEmail.body}", email: "${newEmail.email}", subject: "${newEmail.subject}") {
            Status
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    updateProduct(controls: any,  engine: any = '', mast: any = '', status: any = '', imagesArr:any = null, id): Observable<any> {


      const images = imagesArr.length ? JSON.stringify(imagesArr.join(';')).toString() : null;

      let engineValues = [];
      for (let i=0; i<engine.length; i++) {
        engineValues.push(engine[i].engineName);
      }

      const newProduct ={
        cost:controls['price'].value,
        description: controls['extraInformation']?.value || null,
        engine: engine.length > 1 ?  engineValues.join(',') : engine[0]?.engineName,
        extraFeatures: controls['optionalEquipment']?.value || null,
        maxHeight: controls['maxHeight'].value,
        maxWeight: controls['weight'].value,
        height: controls['height'].value,
        name: controls['name'].value,
        status: status?.statusName || null,
        transmission: controls['transmission'].value,
        mastType: mast[0]?.mastName || null,
        year: controls['year'].value
      }

      const query ={
        "query": `mutation updateProduct {
          update_entities_by_pk(pk_columns: {id: ${id}}, _set: {cost: "${newProduct.cost}", images: ${images}, height: "${newProduct.height}", description: "${newProduct.description}", year: "${newProduct.year}", engine: "${newProduct.engine}",  mastType: "${newProduct.mastType}", extraFeatures: "${newProduct.extraFeatures}", maxHeight: "${newProduct.maxHeight}", maxWeight: "${newProduct.maxWeight}", name: "${newProduct.name}", status: "${newProduct.status}", transmission: "${newProduct.transmission}"}) {
            id
          }
        }`
      };

      return this.http.post(this.mainUrl, query, this.httpOptions);
    }

    getProductPrice(have:string, want:string, amount:number): Observable<any> {

      const query =`https://api.frankfurter.app/latest?amount=${amount}&from=${have}&to=${want}`;

      return this.http.get(query);
    }

}