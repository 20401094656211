import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";
import { Subject } from 'rxjs';

@Injectable({providedIn: 'root'})

export class FilterModalService {

    isModalOpen$ = new Subject<any>();
    
    constructor(@Inject(DOCUMENT) private document: Document) {

    }

    disableBodyScroll(): void {
        this.document.body.style.overflow = 'hidden';
        this.document.body.style.position = 'fixed';
        this.document.body.style.top = '0px';
        this.document.body.style.width = '100%';
    }

    enableBodyScroll(): void {
      this.document.body.style.removeProperty('overflow');
      this.document.body.style.removeProperty('position');
      this.document.body.style.removeProperty('top');
      this.document.body.style.removeProperty('width');
    }

}