<div class="create-tag">
    <div class="create-tag__wrapper">
        <section class="create-tag__section">
            <h1 class="create-tag__header">Tags</h1>
            <h2 class="create-tag__subheader">Create tag</h2>
            <form [formGroup]="createTagForm" class="create-tag__form" (ngSubmit)="addTagSubmit()">
                <label class="create-tag__label" for="name">Tag name:</label>
                <input formControlName="name" class="create-tag__input" type="text" id="name" placeholder="name...">
            
                <button class="create-tag__button" type="submit">Create</button>
            </form>
        </section>

        <section class="create-tag__section section2">
            <h2 class="create-tag__subheader">Update / delete tags</h2>
            <div class="create-tag__table">
                <app-update-tag *ngFor="let tag of tagsList" [tag]="tag" (updateTag)="onUpdateTag($event)" (deleteTag)="onDelete($event)"></app-update-tag>      
            </div>
        </section>

    </div>
</div>