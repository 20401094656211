import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/lib/services/data/data.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-create-product-page',
  templateUrl: './create-product.component.html'
})
export class AppCreateProductPageComponent implements OnInit {

  constructor(private dataService: DataService) {}

  isSubmitted = false;

  statusDropdownList = [
    {
      id: 1, statusName:'new'
    },
    {
      id: 2, statusName:'sale'
    }
  ];
  selectedStatus;
  statusSelectedItem;
  statusDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'statusName',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  tagsDropdownList = [];
  tagsSelectedItems = [];
  tagsDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'tagName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 10,
    allowSearchFilter: true
  };

  engineDropdownList = [
    {
      id: 1, engineName:'gasoline'
    },
    {
      id: 2, engineName:'lpg'
    },
    {
      id: 3, engineName:'diesel'
    },
    {
      id: 4, engineName:'electro'
    }
  ];
  engineSelectedItem;
  engineDropdownSettings = {
    singleSelection: false,
    idField: 'id',
    textField: 'engineName',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  mastDropdownList = [
    {
      id: 1, mastName:'duplex'
    },
    {
      id: 2, mastName:'duplex with free lift'
    },
    {
      id: 3, mastName:'triplex with free lift'
    }
  ];
  mastSelectedItem;
  mastDropdownSettings = {
    singleSelection: true,
    idField: 'id',
    textField: 'mastName',
    itemsShowLimit: 2,
    allowSearchFilter: true
  };

  productForm: FormGroup;

  isErrorMessageVisible:boolean = false;

  imagesArr = [];
  imagesArr$ = new Subject<any>();


  ngOnInit(): void {

    this.imagesArr$.subscribe( (res) => {
      this.imagesArr = res;
      }
    );

    this.dataService.getTags().subscribe((data) => {
      this.tagsDropdownList = data.data.tags;
    });

    this.productForm = new FormGroup({
      name: new FormControl(null, Validators.required),
      price: new FormControl(null, Validators.required),
      weight: new FormControl(null, Validators.required),
      height: new FormControl(null, Validators.required),
      maxHeight: new FormControl(null, Validators.required),
      transmission: new FormControl(null, Validators.required),
      year: new FormControl(null, Validators.required),
      optionalEquipment: new FormControl(null),
      extraInformation: new FormControl(null),
    });
  }

  onStatusSelect(item: any) {
    this.selectedStatus = item;
  }

  onSubmit() {
    this.isErrorMessageVisible = false;
    const controls = this.productForm.controls;
    this.isSubmitted = true;
    if(this.productForm.valid && this.engineSelectedItem) {
      
      this.dataService.addProduct(controls, this.engineSelectedItem, this.mastSelectedItem, this.statusSelectedItem, this.imagesArr).subscribe((product) =>{
        const productId = product.data.insert_entities_one.id
        const tagsArr=[];
        if(this.tagsSelectedItems.length) {
          for(let i=0; i<this.tagsSelectedItems.length; i++) {
            tagsArr.push({entityId: productId, tagId: this.tagsSelectedItems[i].id})
          }
          this.dataService.assignTagsToProduct(tagsArr).subscribe();
        } else {
          this.isSubmitted = false;
        }
        this.productForm.reset();
        this.productForm.markAsUntouched();
        this.isSubmitted = false;
        this.clearAllDropdowns();
      }
        
      );
    } else {
      this.isErrorMessageVisible = true;
    }
 

  }

  clearAllDropdowns() {
    this.engineSelectedItem = [];
    this.mastSelectedItem = [];
    this.statusSelectedItem = [];
    this.tagsSelectedItems = [];
    this.imagesArr = [];
    this.imagesArr$.next([]);
  }

  handleUploadError(an:any) {
  }

  handleUploadSuccess(image:any) {
    this.imagesArr.push(image.url);
    this.imagesArr$.next(this.imagesArr.slice());
  }

  onDelete(index: number) {
    this.imagesArr.splice(index, 1);
    this.imagesArr$.next(this.imagesArr.slice());
  }
}
