<div class="filter-modal__overlay" (click)="clickOnOverlay($event)">
    <div class="filter-modal__wrapper" [formGroup]="filterForm" #formFilter>
        <div class="filter-modal__top">
            <div class="filter-modal__top-section">
                <h3 class="filter-modal__header">{{"filtering.filters" | translate}}</h3>
                <div class="filter-modal__clear" (click)="clearFilters()">{{"filtering.clear" | translate}}</div>
            </div>
            <div class="filter-modal__cross" (click)="closeFilterModal()">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>                    
            </div>
        </div>
        <div class="filters__list">
            <div class="filters__list-item">
                <div class="filters__list-item__wrapper" (click)="toggleFilterOpen(0)">
                    <div class="filters__list-item__name">{{"filtering.lifting-height" | translate}} (m)</div>
                    <div class="filters__list-item__icon" [ngClass]="{'filters__list-item__icon--opened': isFilterOpen[0]}">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                </div>
                <ng-container *ngIf="isFilterOpen[0]">
                    <mat-slider [min]="initValues['liftHeightMin']" [max]="initValues['liftHeightMax']" step="0.1">
                        <input formControlName="liftHeightMin" matSliderStartThumb>
                        <input formControlName="liftHeightMax" matSliderEndThumb>
                    </mat-slider>
                    <div class="filters__range-inputs">
                        <mat-form-field class="example-full-width">
                            <mat-label> {{"filtering.from" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['liftHeightMin']" [value]="filterForm.controls['liftHeightMin']?.value || null">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.to" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['liftHeightMax']" [value]="filterForm.controls['liftHeightMax']?.value || null">
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div class="filters__list-item">
                <div class="filters__list-item__wrapper" (click)="toggleFilterOpen(1)">
                    <div class="filters__list-item__name">{{"filtering.engine" | translate}}</div>
                    <div class="filters__list-item__icon" [ngClass]="{'filters__list-item__icon--opened': isFilterOpen[1]}">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                </div>
                <ng-container *ngIf="isFilterOpen[1]">
                    <p><mat-checkbox formControlName="enginePetrol">{{"filtering.engine-petrol" | translate}}</mat-checkbox></p>
                    <p><mat-checkbox formControlName="engineGas">{{"filtering.engine-gas" | translate}}</mat-checkbox></p>
                    <p><mat-checkbox formControlName="engineDiesel">{{"filtering.engine-diesel" | translate}}</mat-checkbox></p>
                    <p><mat-checkbox formControlName="engineElectro">{{"filtering.engine-electro" | translate}}</mat-checkbox></p>
                </ng-container>
            </div>
            <div class="filters__list-item">
                <div class="filters__list-item__wrapper" (click)="toggleFilterOpen(2)">
                    <div class="filters__list-item__name">{{"filtering.height" | translate}} (m)</div>
                    <div class="filters__list-item__icon" [ngClass]="{'filters__list-item__icon--opened': isFilterOpen[2]}">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                </div>
                <ng-container *ngIf="isFilterOpen[2]">
                    <mat-slider [min]="initValues['heightMin']" [max]="initValues['heightMax']" step="0.1">
                        <input formControlName="heightMin" matSliderStartThumb>
                        <input formControlName="heightMax" matSliderEndThumb>
                    </mat-slider>
                    <div class="filters__range-inputs">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.from" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['heightMin']" [value]="filterForm.controls['heightMin']?.value || null">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.to" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['heightMax']" [value]="filterForm.controls['heightMax']?.value || null">
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div class="filters__list-item">
                <div class="filters__list-item__wrapper" (click)="toggleFilterOpen(3)">
                    <div class="filters__list-item__name">{{"filtering.mast" | translate}}</div>
                    <div class="filters__list-item__icon" [ngClass]="{'filters__list-item__icon--opened': isFilterOpen[3]}">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                </div>
                <ng-container *ngIf="isFilterOpen[3]">
                    <p><mat-checkbox formControlName="mastDuplex">{{"filtering.mast-duplex" | translate}}</mat-checkbox></p>
                    <p><mat-checkbox formControlName="mastDuplexFree">{{"filtering.mast-duplexFree" | translate}}</mat-checkbox></p>
                    <p><mat-checkbox formControlName="mastTriplex">{{"filtering.mast-triplex" | translate}}</mat-checkbox></p> 
                </ng-container>
            </div>
            <div class="filters__list-item">
                <div class="filters__list-item__wrapper" (click)="toggleFilterOpen(4)">
                    <div class="filters__list-item__name">{{"filtering.year" | translate}}</div>
                    <div class="filters__list-item__icon" [ngClass]="{'filters__list-item__icon--opened': isFilterOpen[4]}">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                </div>
                <ng-container *ngIf="isFilterOpen[4]">
                    <mat-slider [min]="initValues['yearMin']" [max]="currentYear">
                        <input formControlName="yearMin" matSliderStartThumb>
                        <input formControlName="yearMax" matSliderEndThumb>
                    </mat-slider>
                    <div class="filters__range-inputs">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.from" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['yearMin']" [value]="filterForm.controls['yearMin']?.value || null">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.to" | translate}}</mat-label>
                            <input matInput [placeholder]="currentYear" [value]="filterForm.controls['yearMax']?.value || null">
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div class="filters__list-item">
                <div class="filters__list-item__wrapper" (click)="toggleFilterOpen(5)">
                    <div class="filters__list-item__name">{{"filtering.lifting-capacity" | translate}} (t)</div>
                    <div class="filters__list-item__icon" [ngClass]="{'filters__list-item__icon--opened': isFilterOpen[5]}">
                        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"/></svg>
                    </div>
                </div>
                <ng-container *ngIf="isFilterOpen[5]">
                    <mat-slider [min]="initValues['liftingCapacityMin']" [max]="initValues['liftingCapacityMax']" step="0.1">
                        <input formControlName="liftingCapacityMin" matSliderStartThumb>
                        <input formControlName="liftingCapacityMax" matSliderEndThumb>
                    </mat-slider>
                    <div class="filters__range-inputs">
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.from" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['liftingCapacityMin']" [value]="filterForm.controls['liftingCapacityMin']?.value || null">
                        </mat-form-field>
                        <mat-form-field class="example-full-width">
                            <mat-label>{{"filtering.to" | translate}}</mat-label>
                            <input matInput [placeholder]="initValues['liftingCapacityMax']" [value]="filterForm.controls['liftingCapacityMax']?.value || null">
                        </mat-form-field>
                    </div>
                </ng-container>
            </div>
            <div class="filter-modal__submit">
                <button (click)="onSubmit()" type="submit" class="filter-modal__submit-button">{{"filtering.apply" | translate}}</button>
            </div>
        </div>

    </div>
</div>