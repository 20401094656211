import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html'
})
export class AppBreadcrumbsComponent implements OnInit {

    public breadcrumbsArr: string[];

    constructor(private router: Router) {}

    ngOnInit(): void {
        this.initBreadcrumb(this.router.url);

        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
          )
            .subscribe(router => {
                this.initBreadcrumb(router['url']);
            });
    }

    initBreadcrumb(urlData: any) {
        let url = urlData.split('/').filter(n => n);
        this.breadcrumbsArr = this.createBreadcrumbArr(url);
    }

    createBreadcrumbArr(arr : string[]) {
        const breadcrumbsArr = arr.reduce((acc, val, index) => {
            const prevUrl = acc[index - 1]?.url || ''
            const obj = {
                text: "navigation." + val,
                url: `${prevUrl}/${val}`
            };
            acc.push(obj);
            return acc;
        }, []);
        return breadcrumbsArr;
    }


}
