import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {

    constructor(private router:Router) {
    }

    isAdminFlag$ = new BehaviorSubject<boolean>(false);
    isAdminFlag: boolean;

    isLoggedIn$ = new BehaviorSubject<boolean>(false);
    isLoggedIn: boolean;

    setIsLoggedInObs(isLoggedIn: boolean) {
        this.isLoggedIn = isLoggedIn;
        this.isLoggedIn$.next(isLoggedIn);
    }

    getIsLoggedInObs() {
        return this.isLoggedIn$;
    }

    setIsAdminFlagObs(flag: boolean) {
        this.isAdminFlag = flag;
        this.isAdminFlag$.next(flag);
    }

    getIsAdminFlagObs() {
        return this.isAdminFlag$;
    }

    setIsLoggedIn(login: string) {
        sessionStorage.setItem('userData', login);
    }

    updateInfoIsLoggedIn(): boolean {
        const isLoggedIn  = !!sessionStorage.getItem('userData')
        this.setIsLoggedInObs(isLoggedIn);

        return isLoggedIn;
    }

    setIsAdminFlag() {
        sessionStorage.setItem('isAdminFlag', 'true');
    }

    updateInfoIsAdminFlag(): boolean {

        const isAdminFlag  = !!sessionStorage.getItem('isAdminFlag')
        this.setIsAdminFlagObs(isAdminFlag);

        return isAdminFlag;
    }

    clearAdminSessionData() {
        sessionStorage.removeItem('userData');
        sessionStorage.removeItem('isAdminFlag');
        this.setIsAdminFlagObs(false);
        this.setIsLoggedInObs(false);
    }
}