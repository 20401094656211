import { Component } from '@angular/core';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us.component.html'
})
export class AppAboutUsPageComponent {
  links = [
    {
      url: "/about-us",
      name: "navigation.about-us"
    },
    {
      url: "/about-us/clients",
      name: "navigation.clients"
    },
    // {
    //   url: "/about-us/loading",
    //   name: "navigation.loading"
    // },
    {
      url: "/about-us/sell-us",
      name: "navigation.sell-us"
    }
  ]
}
