import { Component } from '@angular/core';

@Component({
  selector: 'app-service-repair-page',
  templateUrl: './service-repair.component.html'
})
export class AppServiceRepairPageComponent {
  title = "feedback-form.title-service-repair";
  navLinks = [
    {
      url: "/services",
      name: "navigation.repair-service"
    },
    // {
    //   url: "/services/rent",
    //   name: "navigation.forklift-rental"
    // }
  ]

}
