<ng-container>
  <h1 class="product-list__header">{{"navigation.nav-products" | translate}}</h1>
  <div class="filters">
    <div class="filters__sort" clickOutside (clickOutside)="clickedOutside()" (click)="toggleVariantBlock()">
      <span class="filters__name">{{"sorting.sort" | translate}}: </span> <span class="filters__name-sel">{{selectedSort.name | translate}}</span>
      <div *ngIf="isVariantsOpen" class="filters__sort-variants">
        <div *ngFor="let variant of sortVariants; let i=index" class="filters__sort-variants_var" (click)="selectSort(variant.value, i)">
          {{variant.name | translate}}
        </div>
      </div>
    </div>
    <div class="filters__filter  filters__name" (click)="openFilterModal()">
      {{"filtering.filter" | translate}}
    </div>
  </div>
  <div class="product-list">
    <app-product-item 
      *ngFor="let product of products" [product]="product" [isAdmin]="isAdminFlag && isLoggedIn" (deleteProductItem)="onDeleteItem($event)" (editProductItem)="onEditItem($event)">
  
    </app-product-item>
  </div>
</ng-container>

