import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DataService } from 'src/app/lib/services/data/data.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-create-tag-page',
  templateUrl: './create-tag.component.html'
})

export class AppCreateTagPageComponent implements OnInit{

    constructor(private dataService: DataService) {}

    createTagForm = new FormGroup({
      name: new FormControl('')
    });

    tagsList;
    tagsList$ = new Subject<any>();

    ngOnInit(): void {
      this.tagsList$.subscribe( (res) => {
        this.tagsList = res;
        }
      );

      this.getTags();

    }

    onUpdateTag(tag) {
      this.dataService.updateTag(tag).subscribe((updatedTag) => {
        const newArr = this.tagsList.map(item => {
          if(item.id === tag.id) {
            item.tagName = tag.tagName;
          }
          return item;
        });
        this.tagsList$.next(newArr)
      })
    }

    getTags() {
      this.dataService.getTags().subscribe((data) => {
        this.tagsList$.next(data.data.tags);

      });
    }

    addTagSubmit() {
      if(this.createTagForm.touched) {
        this.dataService.addTag(this.createTagForm.controls.name.value).subscribe((newProduct) => {
          this.tagsList.push(newProduct.data.insert_tags_one);
          this.tagsList$.next(this.tagsList);
          this.createTagForm.reset();
        });
      }
    }

    onDelete(id:number) {
      this.dataService.deleteTag(id);
      this.tagsList$.next(this.tagsList.filter(tag => tag.id !== id));
    }

}
